import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';
import { WebView } from 'react-native-webview';

export default class MyWeb extends Component {
  render() {
    return (
      <View>
      <WebView
        source={{ uri: 'https://twitter.com/elonmusk/status/1469762144786919427?s=20' }}
        style={{ marginTop: 20, width: '100%', height: 250 }}
      />
      <WebView
        source={{ uri: 'https://www.reddit.com/r/dogecoin/comments/qrhcs5/how_to_buy_dogecoin/' }}
        style={{ marginTop: 20, width: '100%', height: 250 }}
      />
            <WebView
        source={{ uri: 'https://www.reddit.com/r/dogecoin/comments/rb7xcp/dogecoin_to_the_moon_chat/' }}
        style={{ marginTop: 20, width: '100%', height: 250}}
      />

      
      </View>
    );
  }
}